/* eslint-disable no-undef */

import ReactOnRails from 'react-on-rails';
import React, { lazy, Suspense } from 'react';
import PageLoader from '@Components/page_loader/view';

const Top = lazy(() => import('@Pages/Top/pc/index'));
const TopSp = lazy(() => import('@Pages/Top/sp/index'));
const List = lazy(() => import('@Pages/List/pc/index'));
const ListSp = lazy(() => import('@Pages/List/sp/index'));
const Contact = lazy(() => import('@Pages/Contact/index'));
const ContactComplete = lazy(() => import('@Pages/Contact/complete'));
const Company = lazy(() => import('@Pages/Company/index'));
const MemberVoice = lazy(() => import('@Pages/MemberVoice/index'));
const CourseInterview = lazy(() => import('@Pages/CourseInterview/index'));
const AboutSite = lazy(() => import('@Pages/AboutSite/index'));
const AboutPersonalInformation = lazy(() => import('@Pages/AboutPersonalInformation/index'));
const Error404 = lazy(() => import('@Pages/Error404/index'));
const Error500 = lazy(() => import('@Pages/Error500/index'));
const CourseInterviewDetail = lazy(() => import('@Pages/CourseInterview/detail'));
const MemberVoiceDetail = lazy(() => import('@Pages/MemberVoice/detail'));
const PeriodicMail = lazy(() => import('@Pages/PeriodicMail/index'));
const PeriodicMailComplete = lazy(() => import('@Pages/PeriodicMail/complete'));
const Info = lazy(() => import('@Pages/Info/pc/index'));
const InfoSp = lazy(() => import('@Pages/Info/sp/index'));
const Knowledge = lazy(() => import('@Pages/Knowledge/index'));
const KnowledgeDetail = lazy(() => import('@Pages/KnowledgeDetail/index'));
const InfoDetailGolf = lazy(() => import('@Pages/InfoDetailGolf/pc/index'));
const InfoDetailNews = lazy(() => import('@Pages/InfoDetailNews/index'));
const InfoDetailGolfSp = lazy(() => import('@Pages/InfoDetailGolf/sp/index'));
const Detail = lazy(() => import('@Pages/Detail/pc/index'));
const DetailSp = lazy(() => import('@Pages/Detail/sp/index'));
const EmployeeDetail = lazy(() => import('@Pages/EmployeeDetail/index'));

ReactOnRails.register({
    Top: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <Top {...props} />
            </Suspense>
        );
    },
    TopSp: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <TopSp {...props} />
            </Suspense>
        );
    },
    Detail: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <Detail {...props} />
            </Suspense>
        );
    },
    DetailSp: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <DetailSp {...props} />
            </Suspense>
        );
    },
    List: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <List {...props} />
            </Suspense>
        );
    },
    ListSp: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <ListSp {...props} />
            </Suspense>
        );
    },
    Contact: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <Contact {...props} />
            </Suspense>
        );
    },
    ContactComplete: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <ContactComplete {...props} />
            </Suspense>
        );
    },
    Company: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <Company {...props} />
            </Suspense>
        );
    },
    EmployeeDetail: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <EmployeeDetail {...props} />
            </Suspense>
        );
    },
    CourseInterview: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <CourseInterview {...props} />
            </Suspense>
        );
    },
    MemberVoice: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <MemberVoice {...props} />
            </Suspense>
        );
    },
    AboutSite: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <AboutSite {...props} />
            </Suspense>
        );
    },
    AboutPersonalInformation: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <AboutPersonalInformation {...props} />
            </Suspense>
        );
    },
    CourseInterviewDetail: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <CourseInterviewDetail {...props} />
            </Suspense>
        );
    },
    MemberVoiceDetail: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <MemberVoiceDetail {...props} />
            </Suspense>
        );
    },
    PeriodicMail: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <PeriodicMail {...props} />
            </Suspense>
        );
    },
    PeriodicMailComplete: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <PeriodicMailComplete {...props} />
            </Suspense>
        );
    },
    Info: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <Info {...props} />
            </Suspense>
        );
    },
    Knowledge: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <Knowledge {...props} />
            </Suspense>
        );
    },
    KnowledgeDetail: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <KnowledgeDetail {...props} />
            </Suspense>
        );
    },
    InfoDetailGolf: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <InfoDetailGolf {...props} />
            </Suspense>
        );
    },
    InfoDetailNews: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <InfoDetailNews {...props} />
            </Suspense>
        );
    },
    InfoSp: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <InfoSp {...props} />
            </Suspense>
        );
    },
    InfoDetailGolfSp: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <InfoDetailGolfSp {...props} />
            </Suspense>
        );
    },
    Error404: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <Error404 {...props} />
            </Suspense>
        );
    },
    Error500: (props) => {
        return (
            <Suspense fallback={<PageLoader />}>
                <Error500 {...props} />
            </Suspense>
        );
    },
});
