import { RotatingLines } from 'react-loader-spinner';
import React from 'react';
import s from './style.module.css';

export const PageLoader = () => {
    return (
        <div className={s.loader_wrapper}>
            <RotatingLines strokeColor='grey' strokeWidth='5' animationDuration='0.75' width='88' visible={true} />
        </div>
    );
};

export default PageLoader;
